import React from "react";

export const Terms = () => {
  return (
    <pre
      style={{
        maxWidth: "800px",
        whiteSpace: "pre-wrap",
      }}
    >
      <p>
        <strong>Last updated: 24 October 2023</strong>
      </p>
      <p>
        1. <strong>Introduction</strong>
      </p>
      <p>
        Welcome to BeamOS. These terms and conditions, together with all
        policies and additional terms they incorporate by reference, including
        the Privacy Policy (collectively these &quot;<strong>Terms</strong>
        &quot;), constitute a legally binding agreement between Orange Pill Inc.
        and each user or participant of the BeamOS websites, applications or
        tools, and services available or accessible through the BeamOS websites
        (collectively the &quot;<strong>Services</strong>&quot;). The Services
        include, but are not necessarily limited to, a website-hosted user
        interface with information about the Beam ecosystem.
      </p>
      <p>
        <strong>IMPORTANT</strong>: Please read these Terms carefully, as they
        govern your use of the Services. This includes your rights and
        obligations, our disclaimers and limitations of legal liability and
        risks described in section 9, relating to your use of, and access to,
        the Services.
      </p>
      <p>
        <strong>
          By accessing or using the Services you confirm that you have read and
          understood, and accepted to be bound by, these Terms. This includes
          your acceptance to a class action waiver and to resolve any disputes
          regarding the Services pursuant to confidential and binding
          arbitration. Do not continue to use the Services if you do not agree
          to these Terms.
        </strong>
      </p>
      <p>
        The following terminology applies to these Terms, including our Privacy
        Policy: &quot;User&quot;, &quot;You&quot; and &quot;Your&quot; refers to
        you, the person using the Services. &quot;Ourselves&quot;,
        &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;, refers to Orange
        Pill Inc. and &quot;Parties&quot; refers to both you and ourselves.
      </p>
      <p>
        2. <strong>About the Services</strong>
      </p>
      <p>
        Our Services are primarily meant to function as information with respect
        to the Beam ecosystem and certain applications relating to such a
        ecosystem.
      </p>
      <p>
        As part of our Services, you may from time to time gain access to
        decentralized open-source smart contracts deployed on public blockchains
        that allow you to mint non-fungible tokens or similar digital assets
        (collectively &quot;<strong>NFTs</strong>&quot;), which in such case
        would become owned by you. Our interface is distinct and separate from
        any of the smart contracts (including protocols) that may be accessed
        through it and is merely one of multiple means of accessing such smart
        contracts. Users can interact with the same smart contracts otherwise
        (for instance directly with the smart contracts), including develop and
        build their own user interfaces on top of such smart contracts.
      </p>
      <p>
        To use our various Services a user may need to use a third-party
        non-custodial wallet software that allows the user to interact with
        public smart contracts and blockchains. We do not operate, maintain, or
        provide any wallets or wallet services. We will at no time have any
        custody or control of any NFTs, smart contracts or blockchains that any
        user is interacting with. We are not a wallet provider, exchange,
        broker, lender, borrower or party to any agreement between any users.
      </p>
      <p>
        Our Services may change and grow in numbers, which may require that we
        include additional terms for certain new services (which in such event
        will be construed as part of the &quot;Services&quot;). We reserve the
        right in our sole discretion to modify or discontinue any parts of the
        Services at any time and without any liability.
      </p>
      <p>
        3. <strong>Conditions for the use of the Services</strong>
      </p>
      <p>
        As a condition to accessing or using the Services, you confirm,
        represent, and warrant the following at all relevant times:
      </p>
      <ul>
        <li>
          <p>
            If you are entering into these Terms as an individual, that you are
            of legal age in the jurisdiction in which you reside (in any event
            over the age of 18) and that you have the legal capacity to enter
            into these Terms and be bound by them. If you are the parent or
            legal guardian of a minor younger than 18 years of age, you may
            allow such minor to use the Services, provided that such minor is of
            the legal age in the jurisdiction in which you reside.
          </p>
        </li>
        <li>
          <p>
            If you are entering into these Terms as an entity, that the user is
            duly organized, validly existing and in good standing under the laws
            of the jurisdiction in which it is organized and that you have all
            requisite power to accept these Terms on that entity&#39;s behalf.
          </p>
        </li>
        <li>
          <p>
            You have all requisite capacity, power and authority to accept and
            carry out and perform your obligations under these Terms, and you
            agreeing to these Terms and using the Services does not constitute
            and would not reasonably be expected to result in a breach or
            violation of any law applicable to you.
          </p>
        </li>
        <li>
          <p>
            You are knowledgeable and experienced in using and evaluating
            blockchain and NFTs.
          </p>
        </li>
        <li>
          <p>
            You are solely responsible for protecting the data integrity and
            confidentiality of your private keys to the extent applicable for
            the Services or any wallet applications or devices used in
            connection with the Services, as we do not collect or store any data
            concerning login information, password or private keys relating to
            your wallet applications or devices.
          </p>
        </li>
        <li>
          <p>
            You are not a person who is (a) subject to national or international
            sanctions or located or ordinarily resident in any country or region
            that is the subject of comprehensive country-wide or region-wide
            sanctions, embargo or equivalent by the US, UK, EU, theUnited
            Nations or the government of a user home country, or (b) listed by
            the US, UK, EU, theUnited Nations or otherwise designated on any
            list of prohibited or restricted parties or persons.
          </p>
        </li>
        <li>
          <p>
            The NFTs that you intend to sell or transfer, or digital assets or
            other funds that you use to purchase NFTs, are not connected to any
            unlawful activities.
          </p>
        </li>
        <li>
          <p>
            You do not, and will not, use a VPN or any other privacy or
            anonymization tools to circumvent, or attempt to circumvent, any
            restrictions that apply to the Services (general use of VPN for
            other purposes shall however be permitted).
          </p>
        </li>
        <li>
          <p>
            You will not conduct any activity that implies infringement or
            violation of any intellectual property rights owned by us or another
            third party, including infringement of copyrights and trademarks.
          </p>
        </li>
        <li>
          <p>
            Your access to the Services complies with all applicable laws, rules
            and regulations and does not contribute to or facilitate any illegal
            activity.
          </p>
        </li>
      </ul>
      <p>
        4. <strong>Prohibited and limited uses</strong>
      </p>
      <p>
        Each user must not, directly or indirectly, in connection with their use
        of the Services:
      </p>
      <ul>
        <li>
          <p>
            Utilize the Services at any time when any representation of the user
            set forth in section 3 above is untrue or inaccurate.
          </p>
        </li>
        <li>
          <p>
            Rely on the Services as a basis for or a source of advice concerning
            any financial decision making or transactions.
          </p>
        </li>
        <li>
          <p>Conduct any activity that seeks to defraud us or a third party.</p>
        </li>
        <li>
          <p>
            Violate, breach, or fail to comply with any applicable provision of
            these Terms.
          </p>
        </li>
        <li>
          <p>
            Interfere with security-related features of the Services, including
            by disabling or circumventing features that prevent or limit use or
            copying of any content, or reverse engineering.
          </p>
        </li>
        <li>
          <p>
            Engage or attempt to engage in or assist any hack of or attack
            (including &quot;sybil attack&quot;, &quot;DoS attack&quot; or
            &quot;griefing attack&quot;) or data gathering or extraction methods
            through use of the Services.
          </p>
        </li>
        <li>
          <p>
            Use the Services to carry out any financial activities that are
            subject to registration or licensing in any jurisdiction.
          </p>
        </li>
        <li>
          <p>
            Commit any violation of applicable laws, rules, or regulations,
            including any regulations relating to market manipulation and wash
            trading.
          </p>
        </li>
        <li>
          <p>
            Engage in any act, practice or course of business that operates to
            circumvent any sanctions targeting the user or the country or
            territory where the user is located.
          </p>
        </li>
      </ul>
      <p>
        The foregoing matters are referred to herein as &quot;Prohibited
        Uses&quot;.
      </p>
      <p>
        We reserve the right to terminate or limit any user&#39;s status or
        access to or use of the Services at any time, with or without notice, if
        we believe that you have violated these Terms. Such terminations and
        limitations may be based on any factor or combination of factors,
        including suspicion that a user has engaged or intends to be engaged in
        any Prohibited Uses.
      </p>
      <p>
        5. <strong>Privacy Policy and cookies</strong>
      </p>
      <p>
        We collect and process your information in accordance with the Privacy
        Policy. We do not use cookies in connection with the Services.
      </p>
      <p>
        6. <strong>Intellectual property rights and ownership</strong>
      </p>
      <p>
        We, our affiliates or our licensors (as applicable) own the intellectual
        property rights for all material and elements of the Services. This
        among other things includes BeamOS&#39; visual interfaces, name and
        logo, text, graphics, images, trademarks, service marks, designs and
        copyrights available through the Services (including the websites), in
        addition to the &quot;look and feel&quot; of the Services. For the
        avoidance of doubt, we do not own any rights to any of the smart
        contracts (including protocols) or blockchains accessible through the
        Services.
      </p>
      <p>
        Subject to these Terms, we grant you a limited, revocable,
        non-exclusive, non-sublicensable, non-transferable license to access and
        use our Services solely in accordance with these Terms. You are not
        permitted to lease, rent, sublicense, publish, copy, modify, adapt,
        translate, reverse engineer, decompile, or disassemble all or a portion
        of the Services without our prior written consent or unless otherwise
        expressly permitted by applicable law.
      </p>
      <p>
        If you choose to provide input and suggestions regarding problems with
        or proposed modifications or improvements to the Services, you grant us
        with an irrevocable, non-exclusive and royalty-free right to utilize
        such modifications or improvements in any manner and for any purpose.
      </p>
      <p>
        We respect and take intellectual property rights of others seriously and
        ask users of the Services to do the same. Infringements of intellectual
        property rights will not be tolerated. If you believe that any content
        made available on or through the Services has been used or exploited in
        a manner that infringes an intellectual property right you own or
        control, please inform us at{" "}
        <a href="mailto:build@onbeam.com">build@onbeam.com</a>.
      </p>
      <p>
        7. <strong>Hyperlinking to our content and removal of links</strong>
      </p>
      <p>
        We reserve the right to request that you remove all links or any
        particular link to our interface. You approve to immediately remove all
        links to our interface upon request. We also reserve the right to amend
        these Terms and its linking policy at any time. By continuously linking
        to our interface, you agree to be bound to and follow these Terms. We
        shall not be hold responsible for any content that appears on your
        website. You agree to protect and defend us against all claims arising
        in this regard.
      </p>
      <p>
        If you find any link on our interface that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to do so or to respond
        to you directly.
      </p>
      <p>
        8. <strong>Fees</strong>
      </p>
      <p>
        Any minting of NFTs may be subject to various fees, including blockchain
        gas fees. Any payments and transactions that a user engages in will be
        processed by self-executing smart contracts. We have no control over
        these payments or transactions and therefore no liability to you or to
        any third party for any claims or damages that may arise because of any
        payments.
      </p>
      <p>
        9. <strong>Disclaimers, risks and limitations of liability</strong>
      </p>
      <p>
        9.1 <strong>No representations or warranties</strong>
      </p>
      <p>
        The Services are being provided on an &quot;AS IS&quot; and &quot;AS
        AVAILABLE&quot; basis. To the fullest extent permitted by law, we are
        not making, and hereby disclaim, any and all information, statements,
        omissions, representations and warranties, express or implied, written
        or oral, equitable, legal or statutory, in connection with the Services
        and the other matters contemplated by these Terms, including any
        representations or warranties of title, non-infringement,
        merchantability, usage, security, uptime, reliability, suitability or
        fitness for any particular purpose, workmanship or technical quality of
        any code or software used in or relating to the Services. User
        acknowledges and agrees that use of the Services is at the user&#39;s
        own risk.
      </p>
      <p>
        9.1 <strong>Limited liability in contract or tort</strong>
      </p>
      <p>
        We shall not be liable to any person, whether in contract, tort
        (including pursuant to any cause of action alleging negligence),
        warranty or otherwise, for any economic or other damages to any user or
        other person, including any special, incidental, consequential,
        indirect, punitive or exemplary damages (including but not limited to
        lost data, lost profits or savings, loss of business or other economic
        loss) arising out of or related to any use of the Services or these
        Terms, regardless of the nature of the cause.
      </p>
      <p>Nothing in this disclaimer will however:</p>
      <ul>
        <li>
          <p>
            Limit or exclude our or your liability for fraud or fraudulent
            misrepresentation.
          </p>
        </li>
        <li>
          <p>
            Limit any of our or your liabilities in any way that is not
            permitted under applicable law.
          </p>
        </li>
      </ul>
      <p>
        9.3 <strong>No professional advice or liability</strong>
      </p>
      <p>
        All information provided by or on behalf of us is for informational
        purposes only and should not be construed as professional, accounting or
        legal advice. Users should not take or refrain from taking any action in
        reliance on any information contained in these Terms or provided by or
        on behalf of us. Before the users make any financial, legal, or other
        decisions relating to the Services, users should seek independent
        professional advice from persons licensed and qualified in the area for
        which such advice would be appropriate.
      </p>
      <p>
        9.4 <strong>Third party services and content</strong>
      </p>
      <p>
        The Services may contain references and links from third parties or to
        third parties, or otherwise display resources, products, services,
        content or smart contracts developed or operated by third parties
        (&quot;<strong>Third Party Materials</strong>&quot;). We do not endorse
        or assume any responsibility for any Third Party Materials or any
        product or services in that relation. If users access any such Third
        Party Materials, the users do so solely at their own risk. Each user
        hereby expressly waives and releases us from all liability arising from
        the user&#39;s use of any Third Party Materials. User further
        acknowledges and agrees that we shall not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be
        caused by or in connection with use of or reliance on any Third Party
        Materials.
      </p>
      <p>
        9.5 <strong>Certain uses and risks of blockchain technology</strong>
      </p>
      <p>
        Technologies that depend on public peer-to-peer networks, such as
        Ethereum, are not under the control or influence of us and are subject
        to inherent risks and uncertainties. Users are solely responsible for
        the safekeeping of the private key associated with the blockchain
        address used in connection with smart contract systems. We will not be
        able to restore or issue any refund in respect of property lost or
        frozen due to loss of private keys or otherwise. If a user is not able
        to use NFTs due to loss or theft of the corresponding private key or
        otherwise, a user will be unable to enjoy the benefits of such NFTs.
      </p>
      <p>
        The smart contract systems available through the Services are software
        utilities which are accessible directly through various blockchain node
        providers or indirectly through compatible wallet applications (such as
        the web browser plugin Metamask) which may interact with a node.
        Interacting with the smart contracts does not require use of the
        Services but our interface may provide a convenient and user-friendly
        method of reading and displaying data from the smart contract systems
        and generating standard transaction messages compatible with the smart
        contract systems. Because we do not provide wallet software or
        blockchain nodes, such software constitutes an essential third party or
        user dependency without which transactions cannot be exercised. We do
        not guarantee the continued operation, maintenance, availability, or
        security of any of the foregoing or APIs, middleware and servers of
        third parties.
      </p>
      <p>
        The compatibility of our interface with wallet applications and devices
        or other third party applications and devices is not intended as, and
        you hereby agree not to construe such compatibility as, an endorsement
        or recommendation thereof or a warranty or assurance regarding the
        fitness or security thereof.
      </p>
      <p>
        In the event that smart contract systems are adversely affected by
        malfunctions, bugs, defects, hacking, theft, attacks, negligent coding
        or design choices, or changes to the protocol rules of the relevant
        blockchain, users may be exposed to a risk of total loss and forfeiture
        of all relevant digital assets (including NFTs). We assume no liability
        or responsibility for any of the foregoing matters.
      </p>
      <p>
        9.6 <strong>Regulatory Uncertainty</strong>
      </p>
      <p>
        Blockchain technologies and NFTs are subject to legal and regulatory
        uncertainties,and their regulatory status remains unclear or unsettled
        in many jurisdictions. It is therefore difficult to predict how or
        whether governments or regulatory agencies may implement changes to law
        or apply existing regulation with respect to such technology and its
        applications.
      </p>
      <p>
        Blockchain technologies and NFTs could be adversely impacted by one or
        more regulatory or legal inquiries, actions, suits, investigations,
        claims, fines or judgments, which could impede or limit the ability of
        the user to continue the use and enjoyment of such technologies or NFTs.
        We must comply with applicable law, which may require us to, upon
        request by government agencies take certain actions or provide
        information.
      </p>
      <p>
        9.7 <strong>Fork Handling</strong>
      </p>
      <p>
        Blockchains may be subject to &quot;forks&quot;. Forks occur when some
        or all persons running the software clients for a particular blockchain
        system adopt a new client or a new version of an existing client that
        (a) changes the protocol rules in backwards-compatible or
        backwards-incompatible manner that affects which transactions can be
        added into later blocks, how later blocks are added to the blockchain,
        or other matters relating to the future operation of the protocol, or
        (b) reorganizes or changes past blocks to alter the history of the
        blockchain. Some forks are &quot;contentious&quot; and thus may result
        in two or more persistent alternative versions of the protocol or
        blockchain, either of which may be viewed as or claimed to be the
        legitimate or genuine continuation of the original. We may not be able
        to anticipate, control or influence the occurrence or outcome of forks,
        and do not assume any risk, liability or obligation in such connection.
        We do not assume any responsibility to notify any user of pending,
        threatened or completed forks. Each user assumes full responsibility to
        independently remain informed about possible forks, and to manage its
        own interests and risks in that connection.
      </p>
      <p>
        9.8 <strong>Information</strong>
      </p>
      <p>
        Although we intend to provide accurate and timely information and data
        on our interface, the interface may not always be entirely accurate,
        complete or current and may also include technical inaccuracies or
        typographical errors. To continue to provide you with as complete and
        accurate information as possible, information may be changed or updated
        from time to time without notice, including information regarding our
        policies. Accordingly, you should verify all information before relying
        on it, and all decisions based on information contained on the interface
        are your sole responsibility.
      </p>
      <p>
        9.9 <strong>Tax risks</strong>
      </p>
      <p>
        Your use of the Services may result in various tax consequences that may
        be adverse to you. We have not undertaken any due diligence or
        investigation into such tax consequences, assume no obligation or
        liability to optimize the tax consequences to any person and are not
        providing any tax advice.
      </p>
      <p>
        9.10 <strong>Viruses, hacking, phishing etc.</strong>
      </p>
      <p>
        The Services may be the target of third-party attacks, including
        phishing. We will not be responsible or liable, directly or indirectly,
        for any actual or alleged loss or damage sustained by you in connection
        with third-party attacks.
      </p>
      <p>
        9.11 <strong>Officers, directors, etc.</strong>
      </p>
      <p>
        All provisions of these Terms which disclaim or limit our obligations or
        liabilities shall also apply equally to our officers, directors,
        members, employees, independent contractors, agents and affiliates.
      </p>
      <p>
        9.12 <strong>Legal limitations on disclaimers</strong>
      </p>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties or
        the limitation or exclusion of certain liabilities and damages.
        Accordingly, some of the disclaimers and limitations set forth in these
        Terms may not apply in full to specific users. The disclaimers and
        limitations of liability provided in these terms shall apply to the
        fullest extent permitted by applicable law.
      </p>
      <p>
        10. <strong>Indemnification</strong>
      </p>
      <p>
        Each user shall defend, indemnify, compensate, reimburse and hold
        harmless us and each of our officers, directors, members, employees,
        independent contractors, agents and affiliates from any claim, demand,
        action, damage, loss, cost or expense, including without limitation
        reasonable attorneys&#39; fees, arising out or relating to (a)
        user&#39;s use of, or conduct in connection with, the Services; (b)
        user&#39;s violation of these Terms (including section 6.2) or any other
        applicable policy or contract; or (c) user&#39;s violation of any rights
        of any other person or entity.
      </p>
      <p>
        11. <strong>Governing law and dispute resolution</strong>
      </p>
      <p>
        11.1 <strong>Governing Law</strong>
      </p>
      <p>
        These Terms shall be governed by and construed and interpreted in
        accordance with the laws of the Netherlands. Although the Services may
        be available in other jurisdictions, the user hereby acknowledges and
        agrees that such availability shall not be deemed to give rise to
        general or specific personal jurisdiction over us in any forum outside
        of the Netherlands.
      </p>
      <p>
        11.2 <strong>Settlement negotiations</strong>
      </p>
      <p>
        If a user has a potential legal dispute, claim or cause of action
        against us, the user shall first (prior to initiating any litigation
        proceedings) contact us by sending an email to{" "}
        <a href="mailto:build@onbeam.com">build@onbeam.com</a> describing the
        nature of the potential dispute, claim or cause of action and providing
        all relevant documentation and evidence thereof. The parties shall use
        commercially reasonable efforts to negotiate a settlement of any such
        legal dispute, claim or cause of action within 60 days of the delivery
        of such email. Any such dispute, claim or cause of action that is not
        finally resolved by a binding, written settlement agreement within such
        60 days shall be brought and resolved exclusively in accordance with
        section 11.3.
      </p>
      <p>
        11.3 <strong>Agreement to binding and exclusive arbitration</strong>
      </p>
      <p>
        <strong>
          Please read the following carefully, because it precludes you from
          submitting a lawsuit in court, participating in any class action or
          having a jury trial.
        </strong>
      </p>
      <p>
        All claims, disputes and controversies directly or indirectly arising
        out of or in connection with or directly or indirectly relating to these
        Terms or any of the matters or transactions contemplated by these Terms
        (for the avoidance of doubt, including any claim seeking to invalidate,
        or alleging that, all or any part of these Terms is unenforceable, void
        or voidable) shall (unless settled pursuant to section 11.2 above) be
        resolved by confidential, binding arbitration to be seated in Amsterdam
        and conducted in the English language by a single arbitrator pursuant to
        the UNICITRAL Arbitration Rules that are most recent at the time when
        the arbitration proceedings are commenced. The award or decision of the
        arbitrator shall be final and binding upon the parties and the parties
        expressly waive any right under the laws of any jurisdiction to appeal
        or otherwise challenge the award, ruling or decision of the arbitrator.
      </p>
      <p>
        Except to the extent necessary to enforce their respective rights under
        these Terms or as otherwise required by applicable law, the parties
        undertake to maintain confidentiality as to the existence and events of
        the arbitration proceedings and as to all submissions, correspondence
        and evidence relating to the arbitration proceedings. This provision
        shall survive the termination of the arbitral proceedings.
      </p>
      <p>
        11.4 <strong>Opt-out procedure</strong>
      </p>
      <p>
        IF YOU ARE A NEW USER OF OUR SERVICES, YOU CAN CHOOSE TO REJECT THIS
        AGREEMENT TO ARBITRATE (&quot;<strong>OPT-OUT</strong>&quot;) BY MAILING
        US A WRITTEN OPT-OUT NOTICE (&quot; <strong>OPT-OUT NOTICE</strong>
        &quot;). THE OPT-OUT NOTICE MUST BE POSTMARKED NO LATER THAN 30 DAYS
        AFTER THE DATE YOU ACCEPT THE TERMS FOR THE FIRST TIME. YOU MUST MAIL
        THE OPT-OUT NOTICE TO{" "}
        <a href="mailto:BUILD@ONBEAM.COM">BUILD@ONBEAM.COM</a>.
      </p>
      <p>
        If the agreement to arbitrate above is found not to apply to you or to a
        particular claim or dispute, either as a result of your decision to opt
        out of the agreement to arbitrate or as a result of a decision by the
        arbitrator or a court order, you agree that any claim or dispute between
        ourselves will be resolved exclusively by the court of Amsterdam. You
        and we agree to submit to the personal jurisdiction of the courts
        located within the Netherlands for the purpose of litigating all such
        claims, disputes, or matters.
      </p>
      <p>
        12. <strong>Miscellaneous</strong>
      </p>
      <p>
        12.1 <strong>Communication and notices</strong>
      </p>
      <p>
        By creating an Account or using our Services, you consent to receive all
        communications, notices and information that we provide in connection
        with these Terms electronically. You agree that we may provide such
        communications, notices and information to you by posting them on our
        website.
      </p>
      <p>
        12.2 <strong>Assignment or transfer</strong>
      </p>
      <p>
        You may not assign or transfer any right to use the Services or any of
        your rights or obligations under these Terms without our express prior
        written consent, including by operation of law or in connection with any
        change of control. We may assign or transfer any or all of our rights or
        obligations under these Terms, in whole or in part, with or without
        notice or obtaining your consent or approval.
      </p>
      <p>
        12.3 <strong>Severability</strong>
      </p>
      <p>
        The invalidity or unenforceability of any of these Terms will not affect
        the validity or enforceability of any other of these Terms, all of which
        will remain in full force and effect.
      </p>
      <p>
        12.4 <strong>Amendments and modifications</strong>
      </p>
      <p>
        These Terms may only be amended, modified, altered or supplemented by or
        with the written consent of us. We reserve, the right, in our sole and
        absolute discretion, to amend, modify, alter or supplement these Terms
        from time to time. The most current version of these Terms will be made
        available through our interface. Any changes or modifications will be
        effective immediately upon the modified Terms being posted to the
        interface. A user shall be responsible for reviewing and becoming
        familiar with any such modifications. Each user hereby waives any right
        such user may have to receive specific notice of such changes or
        modifications. Use of the Services by a user after any modification of
        these Terms constitutes the user&#39;s acceptance of the modified terms
        and conditions. If a user does not agree to any such modifications, the
        user must immediately stop using the Services.
      </p>
      <p>
        12.5 <strong>No implied waivers</strong>
      </p>
      <p>
        No failure or delay on the part of us in the exercise of any power,
        right, privilege or remedy under these Terms shall operate as a waiver
        of such power, right, privilege or remedy, and no single or partial
        exercise of any such power, right, privilege or remedy shall preclude
        any other or further exercise thereof or of any other power, right,
        privilege or remedy.
      </p>
      <p>
        12.6 <strong>Entire Agreement</strong>
      </p>
      <p>
        These Terms constitute the entire agreement between the parties relating
        to the subject matter hereof and supersede all prior or contemporaneous
        agreements and understandings, both written and oral, between the
        parties with respect to the subject matter hereof.
      </p>
    </pre>
  );
};
