import React from "react";

export const Privacy = () => {
  return (
    <pre
      style={{
        maxWidth: "800px",
        whiteSpace: "pre-wrap",
      }}
    >
      <p>
        <strong>PRIVACY POLICY</strong>
      </p>
      <p>
        <strong>Last updated: 24 October 2023</strong>
      </p>

      <p>
        1. <strong>Introduction</strong>
      </p>

      <p>
        Orange Pill Inc. (&quot;we, &quot;us&quot; or &quot;our&quot;) take your
        privacy seriously. In this privacy policy (the &quot;
        <strong>Privacy Policy</strong>&quot;) we have compiled and described
        all essential information about our practices for handling the personal
        data that you provide us with in connection with the BeamOS websites,
        applications or tools, and services available or accessible through the
        BeamOS websites (collectively the &quot;<strong>Services</strong>
        &quot;) and the rights that you have in this regard.Your use of the
        Services is subject to this Privacy Policy and our Terms of Service.
      </p>
      <p>
        We process your personal data for the purposes described in section 4
        titled &quot;How we use your personal data&quot;. We are bound by data
        protection and privacy laws when processing your data, including the
        General Data Protection Regulations Act (&quot;<strong>GDPR</strong>
        &quot;) where applicable. See more on your data protection rights in
        section 7.
      </p>
      <p>
        If you have any questions or enquiries relating to the Privacy Policy,
        please do not hesitate to contact us at{" "}
        <a href="mailto:build@onbeam.com">build@onbeam.com</a>. This is also the
        address that you can contact if you wish to exercise any rights that you
        may have in connection with access or deletion of data or other rights
        you may have under data protection or privacy laws applicable to you.
      </p>
      <p>
        2. <strong>Personal data we collect</strong>
      </p>
      <p>
        We are committed to protecting your privacy and to remain transparent
        about the data that we do collect. We continue to explore solutions to
        further protect user&#39;s privacy, such as opt-out prompts. In
        connection with our Services, we collect the following personal data:
      </p>
      <ul>
        <li>
          <p>
            <strong>Public blockchain information</strong> : When you connect
            your non-custodial blockchain wallet in connection with the
            Services, we collect your blockchain address(es), which may be
            associated with your personal data. Such blockchain addresses are
            publicly available data that are not by themselves personally
            identifying.
          </p>
        </li>
        <li>
          <p>
            <strong>Personal data collected via technology</strong> : We may
            collect information about your web browser type, IP address,
            internet service provider and wallet type (provided by a third party
            wallet provider). This information is used to administer the
            Services and user friendliness.
          </p>
        </li>
        <li>
          <p>
            <strong>Exchange of information and correspondence</strong> : If you
            contact us via email we will receive communications and information
            from you as included in the message.
          </p>
        </li>
      </ul>
      <p>
        Any material changes to the data we collect, or privacy will be
        reflected in an updated Privacy Policy.
      </p>
      <p>
        3. <strong>Cookies</strong>
      </p>
      <p>
        We do not use cookies in connection with our Service <strong>.</strong>
      </p>
      <p>
        4. <strong>How we use your personal data</strong>
      </p>
      <p>
        We utilize your data in accordance with the Terms of Service and as
        required by law for the following purposes:
      </p>
      <ul>
        <li>
          <p>
            Provide you with our Services, including respond to your requests
            and inquiries.
          </p>
        </li>
        <li>
          <p>
            To enable us to investigate and address any activities or conduct
            that may be in violation of our Terms of Service, including to
            detect, prevent and address fraud or other unlawful or harmful
            activities.
          </p>
        </li>
        <li>
          <p>
            We only collect information that you provide us and keep it to
            ourselves. We do not sell or transfer this information to third
            parties.
          </p>
        </li>
        <li>
          <p>
            Comply with applicable laws and to cooperate with any investigations
            or enforcements of authorities or regulators relating to any
            suspected violations of law or legal claims.
          </p>
        </li>
      </ul>
      <p>
        5. <strong>Sharing of data</strong>
      </p>
      <p>
        We may disclose or share your personal data as follows (in which case we
        will always seek to encrypt personal data as much as practicably
        possible prior to sharing):
      </p>
      <ul>
        <li>
          <p>
            <strong>Legal obligations</strong> :Your personal data may be shared
            to comply with legal obligations, such as in connection with fraud
            and illegal activities, regulatory proceedings, court orders,
            compliance measures or otherwise where we may be obliged to do so
            pursuant to applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Restructuring and business changes</strong> :Personal data
            may be transferred by us to another entity in the event of a
            restructuring, merger, demerger, bankruptcy or similar.
          </p>
        </li>
        <li>
          <p>
            <strong>With your consent</strong> : Your personal data may be
            shared if you provide us with your consent to do so.
          </p>
        </li>
      </ul>
      <p>
        6. <strong>Third party websites and wallets</strong>
      </p>
      <p>
        Our Services may contain links to third party websites. When you click
        on a link to any other website or location, you will leave our Services,
        and another entity may collect personal data from you. We have no
        control over, do not review, and cannot be responsible for these
        third-party websites or their content. This Privacy Policy does not
        apply to these third-party websites or their content, or to any
        collection of your personal data after you click on links to such
        third-party websites. We encourage you to read the privacy policies of
        every website you visit.
      </p>
      <p>
        Your interaction with any third-party wallet is governed by the
        applicable terms and privacy policy of the third party providing you
        with such wallet. You are responsible for the security of your digital
        wallet, and we urge you to take steps to ensure it is and remains
        secure. If you discover an issue related to your wallet, please contact
        your wallet provider.
      </p>
      <p>
        7. <strong>Your data protection rights</strong>
      </p>
      <p>
        You may have certain data protection rights according to law applicable
        to you, including:
      </p>
      <ul>
        <li>
          <p>
            <strong>Right of access</strong> : You have the right to ask us for
            copies of your personal data.
          </p>
        </li>
        <li>
          <p>
            <strong>Right to erase</strong> : You have the right to ask us to
            erase your personal information in certain circumstances in
            accordance with applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Right to restriction of processing</strong> : You have the
            right to ask us to restrict the processing of your personal data in
            certain circumstances in accordance with applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Right to object to processing</strong> : You have the right
            to object to the processing of your personal information in certain
            circumstances in accordance with applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Right to data portability</strong> : You have the right to
            ask that we transfer the personal data you gave us to another
            organization, or to you, in certain circumstances in accordance with
            applicable law.
          </p>
        </li>
        <li>
          <p>
            <strong>Right to withdraw consent</strong> : If we have collected
            and processed your personal data with your consent, you have the
            right to withdraw your consent at any time.
          </p>
        </li>
      </ul>
      <p>
        8. <strong>Data retention</strong>
      </p>
      <p>
        We may retain your personal data as long as you continue to use the
        Service or for as long as is necessary to fulfill the purposes outlined
        in this Privacy Policy. Subject to applicable laws, we may continue to
        retain your personal data even after you cease to use the Services if
        such retention is necessary to comply with our legal obligations, to
        resolve disputes, prevent fraud and abuse, enforce our Terms of Service
        or other agreements, or protect our legitimate interests. Where your
        personal data is no longer required for these purposes, we will delete
        it.
      </p>
      <p>
        9. <strong>Changes to the Privacy Policy</strong>
      </p>
      <p>
        This Privacy Policy may be updated from time to time for any reason. We
        will notify you of any changes to our Privacy Policy by posting the new
        Privacy Policy at <em>os.onbeam.com</em>. The date the Privacy Policy
        was last revised is identified at the beginning of this Privacy Policy.
        You are responsible for periodically visiting our Services and this
        Privacy Policy to check for any changes.
      </p>
    </pre>
  );
};
